<script>
import { mapGetters } from "vuex";
export default {
  name: "acc-listings-entry",

  props: {
    entry: {
      type: Object,
    },
  },

  data() {
    return {
      valid_in_search: false,
      valid_in_featured: false,
    };
  },

  created() {
    this.valid_in_search = this.expiry_left > 0;
  },

  computed: {
    ...mapGetters("config", ["get_category", "get_location"]),
    category() {
      return this.get_category(this.entry.ltg_cat);
    },
    location() {
      return this.get_location(this.entry.ltg_lctn);
    },
    title() {
      let title = "Anuncio incompleto";
      if (this.category && this.location) {
        title = this.category.label;
        if (this.location) {
          title += ` en ${this.location.loc_name_short}`;
        }
      }
      return title;
    },
    ltg_display_end_date() {
      let ltg_display_end_date = this.entry.ltg_display_end_date || 0;
      ltg_display_end_date = ltg_display_end_date * 1000;
      return ltg_display_end_date;
    },
    expiry_left() {
      return this.ltg_display_end_date - Date.now();
    },
  },

  methods: {
    stopPropagation(e) {
      // console.log(e);
      e.stopPropagation();
    },
  },
};
</script>

<template>
  <div>
    <div class="entry-picture">
      <div class="featured-cover">
        <!-- <img class="size-holder size-holder--landscape" src="/img/holders/sizeholder-landscape.png" /> -->
        <img class="size-holder" src="/img/holders/sizeholder-square.png" />
        <picture v-if="entry.ltg_images.length" class="cover">
          <img
            :src="entry.ltg_images[0].src"
            :srcset="entry.ltg_images[0].srcset"
            sizes="120px"
          />
        </picture>
        <div v-else class="cover"></div>
      </div>
    </div>
    <div class="content-area">
      <div class="entry-header">
        <div class="entry-title">
          <span class="da__label">Anuncio </span>#{{ entry.ltg_id }}
        </div>
        <countdown
          v-if="valid_in_search"
          tag="div"
          :time="expiry_left"
          @end="valid_in_search = false"
          class="entry-expiry good"
        >
          <template slot-scope="props">
            <!-- <span class="da__label">Expira en </span> -->
            <span class="da__value">
              <template v-if="props.days">
                {{ props.days }}
                {{ props.days > 1 ? "días" : "día" }}
              </template>
              <template v-else-if="props.hours">
                {{ props.hours }}
                {{ props.hours > 1 ? "horas" : "hora" }}
              </template>
              <template v-else-if="props.minutes">
                {{ props.minutes }}
                {{ props.minutes > 1 ? "minutos" : "minuto" }}
              </template>
            </span>
          </template>
        </countdown>
        <div v-else class="entry-expiry">Expirado</div>
        <div
          class="optional entry-renew"
          :class="{ good: entry.ltg_display_autorenew }"
        >
          <i class="da__icon fas fa-sync-alt"></i>
          <span class="da__label">Renovar</span>
        </div>
        <div
          class="optional entry-premium"
          :class="{ good: valid_in_featured }"
        >
          <i class="da__icon fas fa-star"></i>
          <span class="da__label">Premium</span>
        </div>
      </div>

      <div class="entry-category">
        <!-- <span class="data__label">Tipo: </span> -->
        <span class="data__value" v-if="category">{{ category.label }}</span>
        <span class="data__value" v-else>Pendiente</span>
      </div>
      <div class="entry-location">
        <!-- <span class="data__label">Ubicación: </span> -->
        <!-- <span class="data__value" v-if="location">{{ location.loc_name_short }}</span> -->
        <span class="data__value" v-if="location">{{
          location.loc_name_long
        }}</span>
        <span class="data__value" v-else>Pendiente</span>
      </div>

      <!-- <div class="box__details">
        <div class="da--ltg_room">
          <span class="da__value">{{ entry.ltg_room || "-" }}</span>
          <span class="da__label" v-if="entry.ltg_room > 1">Habs.</span>
          <span class="da__label" v-else>Hab.</span>
        </div>
        <div class="da--ltg_bath">
          <span class="da__value">{{ entry.ltg_bath || "-" }}</span>
          <span class="da__label" v-if="entry.ltg_bath > 1">Baños</span>
          <span class="da__label" v-else>Baño</span>
        </div>
        <div class="da--ltg_park">
          <span class="da__value">{{ entry.ltg_park || "-" }}</span>
          <span class="da__label" v-if="entry.ltg_park > 1">Parqueos</span>
          <span class="da__label" v-else>Parqueo</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.countdown {
  .unit:not(:last-child):after {
    content: ", ";
  }
}
</style>
